// @flow strict
import React from 'react';
import Mailchimp from 'react-mailchimp-form'
import styles from './EmailForm.module.scss'

type Props = {
};

const EmailForm = ({ }: Props) => {

    return (
        <div class={styles['mailchimpSignup']}>
        <span> Get first dibs on new content. Sign up for the newsletter!</span>
        <Mailchimp
        action='https://offerletter.us3.list-manage.com/subscribe/post?u=31ec9e0bc0352c56a42cd38c9&amp;id=38a9838351'
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'email',
            type: 'email',
            required: true
		  },
		  {
			name: 'NAME',
			placeholder: 'name',
			type: 'text',
			required: true
		  }
        ]}
        />
        </div>
    );
};

export default EmailForm;
